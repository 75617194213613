#graphics{

    position: fixed;
    top: 0px;
    left: 0px;

    #sky{

        width: 100vw;        
        height: 100vh;
        // background-color: red;
    
        // .c-side-f{

        //     background-color: white;
        // }
        
        // .c-side-l{
            
            
        //     background-color: rgb(225, 225, 225);
        // }
        
        // .c-side-r{
            
        //     background-color: white;
            
        // }
        
        // .c-bottom{
            
            
        //     background-color: rgb(192, 192, 192);
        // }
    }
}