.cookie-banner {
    background-color: #e4e4e4;
    width: 500px;
    max-width: 96%;
    position: fixed;
    bottom: 80px;
    left: calc(50% - 500px / 2 - 10px);
    z-index: 10030;
    padding: 20px;
    box-shadow: 0vw 0vh 1px 800vw rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    // display: grid;
    // grid-template-rows: 1fr 130px 50px;

    @media (max-width: 600px) {

        bottom: 10px;
        left: 2%;
        box-sizing: border-box;
        // height: 96%;
    }

    max-height: 96vh;
    // overflow: auto;

    .cookies-text {

        @media (max-width: 600px) {
            overflow: auto;
            max-height: 75vh;
            box-shadow: inset 0px -10px 10px -10px #9f9f9f;
        }
    }

    #consent-toggle {

        padding-top: 20px;
    }

    h3 {
        text-align: center;
        letter-spacing: 1px;
    }

    p {
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    button {

        text-transform: capitalize;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid rgb(157, 157, 157);


        &:hover {
            border: 2px solid rgb(61, 61, 61) !important;
        }
    }

    .consent-buttons {

        margin-top: 3px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5px;
        // capitalize;

        .consent-all-cookies {
            // border: 2px solid rgb(203, 203, 203);
            // border: 2px solid rgb(255, 225, 170);
            border: 2px solid var(--accent-col);
        }

        .customise-cookies {
            // border: 2px solid rgb(203, 203, 203);
            border: 1px solid rgb(134, 134, 134);
            background-color: rgba(255, 255, 255, 0.231);
            // color: white;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }

    input:checked+.slider {
        // background-color: #2196f3;
        background-color: var(--accent-col);

    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.toggle-label {
    // margin-bottom: 20px;
    vertical-align: middle;
    transform: translateY(30%);
    width: 120px;
    display: inline-block;
    // vertical-align: sub;
}

#privacy-btn-wrapper {
    position: absolute;
    right: 20px;
}

.clear-font * {

    font-family: Arial, Helvetica, sans-serif !important;
}

#update-cookies-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}