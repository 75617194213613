// @use "standards";
// @use "trim_sets";

html {

    // background: linear-gradient(rgb(209, 209, 254) 60%, white);
    background: linear-gradient(rgb(200, 200, 248) 50%, rgb(230, 230, 230));
}

main {

    z-index: 2;
    position: relative;
    top: 0px;
    // height: 1000px;
    // margin-top: 70px;
}

#website-description {

    // start in column 1 and end in -1

    grid-column-start: 1;
    grid-column-end: -1;
    padding: var(--gutter-width);
    // background-color: var(--primary-col-lighter);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);
    backdrop-filter: blur(4px);
    margin-bottom: 20px;

    h1 {

        margin: 0px;
        text-align: center;
    }

    p {

        margin: 0px;
    }
}

#trim-editor {

    background-color: var(--primary-col);
    grid-column-start: 1;
    grid-column-end: -1;
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);
    backdrop-filter: blur(5px);

    // box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 630px) {

        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        height: 70vh;
    }

    grid-template-rows: 1fr 200px;
    column-gap: var(--gutter-width);
    row-gap: var(--gutter-width);
    margin-bottom: 20px;
    padding: var(--gutter-width);

    #trim-display {

        background-color: var(--primary-col-lighter);
        margin-right: 0px;
        max-width: 100%;

        >canvas {

            max-width: 100%;
        }
    }

    #edit-trim-model {

        margin-left: 0px;

        display: grid;
        grid-template-rows: auto auto;

        >* {

            grid-column-start: 0;
            grid-column-end: 1;
        }

        >#edit-all {

            grid-row-start: 1;
            grid-row-end: 2;

            margin-bottom: var(--gutter-width);
        }

        >#edit-single {

            grid-row-start: 2;
            grid-row-end: 3;

            >.hide-leather {
                // --trim-opts-count: 4 !important;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;

                >div:nth-child(2)>img {

                    // visibility: hidden;
                    opacity: 0.3;
                }
            }

            >div {

                padding: 0px 5px;

                &:first-of-type {

                    padding-top: 5px;
                }

                &:last-of-type {

                    padding-bottom: 5px;
                }
            }
        }

        >#edit-all,
        #edit-single {

            width: 100%;

            >div {

                --trim-opts-count: 5;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(var(--trim-opts-count), 1fr);
                background-color: var(--primary-col-lighter);
                // padding: 0px 5px;
                box-sizing: border-box;
                border-radius: 5px;

                //display and drop container
                >div {
                    // width: 70px;
                    // width: calc(100% - 10px - 1px * 5);
                    width: 100%;
                    height: fit-content;

                    // box-sizing: border-box;
                    position: relative;
                    top: 0px;

                    // margin: 3px;
                    margin: calc(var(--gutter-width)/ 6) 0px;
                    aspect-ratio: 1/1;
                    transition: .05s;

                    // --inner-padding: 15px;
                    --inner-padding: 15%;


                    >img:first-of-type {

                        z-index: 5;
                        position: relative;
                        height: 100%;
                        width: 100%;
                        image-rendering: pixelated;
                        padding: var(--inner-padding);
                        box-sizing: border-box;
                    }

                    >.dropdown {

                        border-radius: 5px;
                        transition: 0.1s;
                        position: absolute;
                        top: 100%;
                        height: auto;
                        width: calc(260%);
                        max-width: 170px;
                        z-index: 200;
                        background-color: var(--primary-col-trans);
                        border: 1px solid var(--primary-col-darker);
                        box-sizing: border-box;

                        display: grid;
                        grid-template-columns: repeat(3, 1fr);

                        --gap: 1px;

                        padding: var(--gap);

                        >img {
                            aspect-ratio: 1/1;
                            margin: var(--gap);
                            // background-color: blue;
                            width: calc(100% - var(--gap)*2);
                            image-rendering: pixelated;
                        }
                    }
                }
            }
        }
    }

    #model-handling {

        display: grid;
        grid-template-rows: 20px 1fr;
        row-gap: 20px;
        min-height: 200px;

        >nav {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: calc(var(--gutter-width) / 2);

            >p {
                border-radius: 10px;
                background-color: var(--primary-col-lighter);
                text-align: center;
                padding: 5px;
                margin: 0px;
                transition: 0.1s;
                text-transform: capitalize;
                color: var(--text-col-2);

                &:hover {

                    background-color: var(--primary-col-darkest-e);
                }
            }

            .selected {

                // height: 100px;
                transform: scaleY(100%) translateY(8px);
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;

                &:hover {
                    background-color: var(--primary-col-lighter);
                }
            }
        }

        #handlers {

            >div {

                background-color: var(--primary-col-lighter);
                width: 100%;
                height: 80%;
                padding: 5px;
                box-sizing: border-box;

                p {

                    margin: 0px;
                }
            }

            #skin-handling {

                >div {

                    display: grid;
                    // grid-template-columns: repeat(3, 1fr);
                    grid-template-columns: 100px 1fr 1fr;
                    column-gap: 20px;

                    >* {

                        width: 100%;
                    }

                }
            }

            #camera-handling {

                >div {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: calc(var(--gutter-width) / 4);

                    button {
                        text-transform: capitalize;
                        padding: 10px 0px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                        font-size: 16px;

                    }

                    .selected-rot-type {

                        background-color: var(--accent-col);
                    }

                }

                h2 {
                    margin: 10px 0px 0px 0px;
                    font-size: 20px;
                }

                >input {
                    width: 100%;
                    border: none;
                }
            }
        }
    }
}

#extra-menu {

    padding: 20px;
    box-sizing: border-box;
    grid-row-start: 1;
    grid-row-end: 3;
    // position: relative;
    // top: 0px;
    width: 77% !important;
    // height: 100px;
    background-color: var(--extra-menu-color);
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    z-index: 40;
    border-radius: 5px;

    * {

        transition-duration: 0s !important;
    }

    >div {

        margin: 10px 0px;
    }

    h2 {

        margin: 0px 0px;
    }

    #extra-menu-armor {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    #leather-options-full {

        >div {

            display: grid;
            grid-template-columns: 1fr auto 1fr auto 1fr;

            p {
                margin: 10px;
                text-align: center;
                transform: translateY(20%);
            }

            >div {

                margin: auto;
            }

            input {

                height: 100%;
                width: 80%;
                // margin: 5px;
                border: 1px solid rgb(189, 189, 189);
                border-radius: 5px;
                // aspect-ratio: 1/0.75;
            }
        }
    }

    #give-command {

        >p {

            margin-bottom: 5px;
            color: gray;
        }

        >input {

            width: 90%;
            border: rgb(210, 210, 210) solid 1px;
            border-radius: 5px;
            outline-color: #93f793;
            color: gray;

        }
    }
}

#dice {

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 20px;
    // margin-bottom: 73px;
    padding: 5px;

    .die {

        border: 2px solid var(--primary-col);
        border-radius: 6px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20%;
        padding: 10%;
        width: 70%;
        margin-left: 15%;
        box-sizing: border-box;
        aspect-ratio: 1/1;
        background-color: var(--primary-col-lighter);

        >.die-blob {
            background-color: var(--primary-col-darkest-e);
            border-radius: 100px;
        }

        &:active {

            transition: .2s;
            transform: translateY(-10px);
        }
    }
}

//display and drop container
.dropdown-box {
    // width: 70px;
    // width: calc(100% - 10px - 1px * 5);
    width: 100%;
    height: fit-content;

    // box-sizing: border-box;
    position: relative;
    top: 0px;

    // margin: 3px;
    margin: calc(var(--gutter-width)/ 6) 0px;
    aspect-ratio: 1/1;
    transition: .05s;

    // --inner-padding: 15px;
    --inner-padding: 15%;


    >img:first-of-type {

        z-index: 5;
        position: relative;
        height: 100%;
        width: 100%;
        image-rendering: pixelated;
        padding: var(--inner-padding);
        box-sizing: border-box;
    }

    >.dropdown {

        max-width: 170px;
        border-radius: 5px;
        transition: 0.1s;
        position: absolute;
        top: 100%;
        height: auto;
        width: calc(260%);
        z-index: 100;
        background-color: var(--primary-col-trans);
        border: 1px solid var(--primary-col-darker);
        box-sizing: border-box;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        --gap: 1px;

        padding: var(--gap);

        >img {
            aspect-ratio: 1/1;
            margin: var(--gap);
            // background-color: blue;
            width: calc(100% - var(--gap)*2);
            image-rendering: pixelated;
        }
    }
}

// .dropdown-box {
//     // width: 70px;
//     width: calc(100% - 10px - 1px * 5);
//     position: relative;
//     top: 0px;

//     margin: 5px;
//     aspect-ratio: 1/1;
//     transition: .05s;

//     // >img:first-of-type {

//     //     z-index: 5;
//     //     position: relative;
//     //     height: 100%;
//     //     width: 100%;
//     //     image-rendering: pixelated;
//     //     padding: 3px;
//     //     box-sizing: border-box;
//     // }

//     >.dropdown {

//         border-radius: 5px;
//         transition: 0.1s;
//         position: absolute;
//         top: 100%;
//         height: auto;
//         width: calc(260%);
//         z-index: 100;
//         background-color: var(--primary-col-trans);
//         border: 1px solid var(--primary-col-darker);
//         box-sizing: border-box;

//         display: grid;
//         grid-template-columns: repeat(3, 1fr);

//         --gap: 1px;

//         padding: var(--gap);

//         >img {
//             aspect-ratio: 1/1;
//             margin: var(--gap);
//             // background-color: blue;
//             width: calc(100% - var(--gap)*2);
//             image-rendering: pixelated;
//         }
//     }
// }