// @use "standards";

#more-tools {

    --marging-l: 30%;
    
    @media (max-width: 1100px) {
        
        --marging-l: 50px;
    }


    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    margin-left: calc(var(--marging-l)/2) !important;
    width: calc(100% - var(--marging-l));

    #btn-click-text {

        text-align: center;
        grid-column: 1 / 4;
        // background-color: var(--primary-col-lighter);
        margin-left: 25%;
        width: 50%;
        transition: 0.4s;
        // color: var(--primary-col-lighter);
        color: rgba(39, 39, 39, 0);
    }

    .btn-text-clicked {

        color: rgb(39, 39, 39) !important;
    }

    >div {

        display: block;
        width: 100%;

        >button {

            border-radius: 5px;

            aspect-ratio: 1;
            width: 100%;
            display: block;

            border: none;
            background-color: var(--primary-col-lighter);
            display: grid;

            transition: 0.1s;

            >img {

                --w: 60%;
                width: var(--w);
                margin-left: calc(50% - var(--w) / 2);
                display: block;
                filter: brightness(0) invert(1) brightness(0.3);
            }

            &:hover {

                transform: scale(1.1);
                // background-color: var(--primary-col-darkest-e);
                // color: rgb(230, 230, 230);
                // >img {
                //     filter: brightness(0) invert(1) brightness(0.9);
                // }
            }
        }
    }

    #discord-logo {

        padding: 8px 0px;
    }

    input {

        border: 1px solid rgb(237, 237, 237);
        background-color: var(--primary-col);
    }
}