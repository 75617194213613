// @use "standards";

#all-trims {

    >p {

        grid-column-start: 1;
        grid-column-end: -1;
        margin-top: 0px;
        margin-bottom: 5px;
    }
}

#selected-trims,
#all-trims {

    z-index: -1 !important;
    grid-column-start: 1;
    grid-column-end: -1;
    // width: 100%;
    // height: 100px;

    // background-color: var(--primary-col);
    // border-radius: 5px;
    // backdrop-filter: blur(5px);
    // box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);

    margin-bottom: 20px;

    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 630px) {

        grid-template-columns: repeat(1, 1fr);
    }

    column-gap: 5px;
    row-gap: 5px;
    // box-sizing: border-box;
    display: grid;

    >h2 {

        grid-column-start: 1;
        grid-column-end: -1;
        margin: 5px 0px;
    }

    >.trim-card {

        background-color: var(--primary-col);
        border-radius: 5px;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);
        // box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 10px;


        @media (max-width: 814px) {

            padding-bottom: 65px;
        }

        --craft-b-w: 30px;

        h2,
        h3 {

            margin-top: 0px;
            color: rgb(41, 41, 41);
            text-transform: capitalize;
        }

        h2 {

            display: inline-block;
            vertical-align: middle;
        }

        >div>.trim-img {

            height: 20%;
            image-rendering: pixelated;
            margin-right: 5px;
        }

        >div>img {

            // height: 20%;
            // image-rendering: pixelated;
            // margin-right: 5px;
            transform: translateY(3px);
        }

        h3 {

            text-align: center;
            margin-right: 2px;
        }

        p {

            color: rgb(115, 115, 115);
            display: inline-block;
            margin-top: 0px;
            margin-bottom: 5px;
        }

        input {

            width: 50px;
            border-radius: 5px;
            border: none;
            background-color: rgba(255, 255, 255, 0.693);
            padding-left: 5px;
            box-sizing: border-box;
            color: rgb(95, 95, 95);
        }

        .desc {

            color: rgb(64, 64, 64) !important;
            margin-bottom: 20px;
        }

        .craft {

            padding: 4px;
            box-shadow: 0 4px 30px rgba(86, 86, 86, 0.1);
            background-color: var(--primary-col);

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            column-gap: 4px;
            row-gap: 4px;
            border-radius: 2px;

            >div {

                width: var(--craft-b-w);
                height: var(--craft-b-w);
                background-color: rgba(255, 255, 255, 0.476);
                // margin: 3px;
                border-radius: 2px;

                >img {

                    // set size to x% of parent div but still in center. x is a variable
                    --scale: 0.9;
                    width: calc(100% * var(--scale));
                    height: calc(100% * var(--scale));
                    margin: calc((100% - var(--scale) * 100%) / 2);
                    image-rendering: pixelated;
                }
            }
        }
    }
}