// @use "standards";
@media (min-width: 630px) {

    #trim-sets {

        --sets-width: 100px;
        position: absolute;
        top: 170px;
        left: calc(var(--grid-margin) - var(--sets-width) + 15px);
        // z-index: -1;

        #sets-holder {

            >div {

                &:not(:last-child) {
                    // border: 1px solid gray;
                    background-color: var(--primary-col-lighter);
                    margin-bottom: 10px;
                    // transform: translateX(-10px);
                }

                &:hover {

                    transform: translateX(-10px);
                }

                transition: .05s;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                padding: 10px;
                box-sizing: border-box;
                width: var(--sets-width);
                height: 80px;

                backdrop-filter: blur(5px);
            }
        }

        .selected-trim {

            background-color: var(--accent-col) !important;
            transform: translateX(-10px);
            color: white;
        }

        #add-trim-set-btn {

            background-color: var(--primary-col-darkest);

            >div {

                background-color: rgba(255, 255, 255, 0.62);
                height: 100%;
                aspect-ratio: 1/1;
                border-radius: 1000px;
                display: grid;
                padding: 10px;
                box-sizing: border-box;

                >div {

                    --line-thickness: 6px;
                    grid-row-start: 1;
                    grid-column-start: 1;

                    background-color: var(--accent-col);
                    border-radius: 100px;

                    &:first-of-type {

                        width: var(--line-thickness);
                        height: 100%;
                        margin-left: calc(50% - var(--line-thickness)/2);
                    }

                    &:last-of-type {

                        height: var(--line-thickness);
                        width: 100%;
                        margin-top: calc(50% - var(--line-thickness)/2);
                    }
                }
            }
        }

        #trim-delete {

            position: absolute;
            right: 120px;
            top: 0px;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background-color: rgb(234, 234, 234);

            &:hover {

                background-color: rgb(147, 247, 147) !important;

            }

            img {
                padding: 10%;
                width: 80%;
                height: 80%;
                opacity: 0.4;
            }
        }
    }
}

@media (min-width: 630px) {

    #toggle-trim-sets {

        visibility: hidden;
    }
}

// @use "standards";
@media (max-width: 630px) {

    #trim-sets {

        --sets-width: 80px;
        position: absolute;
        top: 170px;
        left: -10px;
        z-index: 250;
        transition: 0.2s;

        #sets-holder {

            >div {

                &:not(:last-child) {
                    // border: 1px solid gray;
                    background-color: var(--primary-col-lighter);
                    margin-bottom: 10px;
                    // transform: translateX(-10px);
                }

                &:hover {

                    transform: translateX(10px);
                }

                transition: .05s;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                padding: 10px;
                box-sizing: border-box;
                width: var(--sets-width);
                height: 80px;

                backdrop-filter: blur(5px);
            }
        }

        .selected-trim {

            background-color: var(--accent-col) !important;
            transform: translateX(10px);
            color: white;
        }

        #add-trim-set-btn {

            background-color: var(--primary-col-darkest);

            >div {

                background-color: rgba(255, 255, 255, 0.62);
                height: 100%;
                aspect-ratio: 1/1;
                border-radius: 1000px;
                display: grid;
                padding: 10px;
                box-sizing: border-box;

                >div {

                    --line-thickness: 6px;
                    grid-row-start: 1;
                    grid-column-start: 1;

                    background-color: var(--accent-col);
                    border-radius: 100px;

                    &:first-of-type {

                        width: var(--line-thickness);
                        height: 100%;
                        margin-left: calc(50% - var(--line-thickness)/2);
                    }

                    &:last-of-type {

                        height: var(--line-thickness);
                        width: 100%;
                        margin-top: calc(50% - var(--line-thickness)/2);
                    }
                }
            }
        }

        #trim-delete {

            position: absolute;
            left: 120px;
            top: 0px;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background-color: rgb(234, 234, 234);

            &:hover {

                background-color: rgb(147, 247, 147) !important;

            }

            img {
                padding: 10%;
                width: 80%;
                height: 80%;
                opacity: 0.4;
            }
        }
    }

    #toggle-trim-sets {

        position: fixed;
        aspect-ratio: 1;
        top: 10px;
        left: 10px;
        border-radius: 100px;
        border: none;
        z-index: 1250;
    }

    .hide-trim-sets {

        left: -190px !important;
    }
}