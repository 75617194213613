// Grid --------------------------------------

:root {
    --column-width: 100px;
    --gutter-width: 20px;
    --column-count: 8;

    // --grid-width: calc(var(--column-width) * var(--column-count) + var(--gutter-width) * (var(--column-count)-1));
    --grid-width: calc(var(--column-width) * var(--column-count) + var(--gutter-width) * (var(--column-count) - 1));
    --grid-margin: calc((100% - var(--grid-width)) / 2);
    --column-gutter-width: calc(var(--column-width) + var(--gutter-width));
}

* {

    font-family: "Roboto", sans-serif;
    // font-weight: 100;
    // font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@media (max-width: 1440px) {
    :root {
        --column-width: 100px;
        --gutter-width: 20px;
        --column-count: 8;
    }
}

@media (max-width: 1100px) {
    :root {
        --column-width: 70px;
        --gutter-width: 20px;
        --column-count: 8;
    }
}

@media (max-width: 810px) {
    :root {
        --column-width: 50px;
        --gutter-width: 20px;
        --column-count: 8;
    }
}

@media (max-width: 630px) {
    :root {
        --column-width: calc((100% - 20px - 10px * 4) / 5);
        --gutter-width: 10px;
        --column-count: 5;
    }
}

.standard-grid {

    display: grid;

    @media (max-width: 630px) {
        margin-top: 70px;
    }

    // width: var(--grid-width);
    grid-template-columns: repeat(var(--column-count), var(--column-width));
    column-gap: var(--gutter-width);
    margin-left: var(--grid-margin);

}

// Text ----------------------------------------------------------

h1 {

    color: rgb(22, 22, 22);
}

h2 {

    color: var(--text-col-2);
}

h3 {

    color: rgb(61, 61, 61);
}

p,
button {

    color: var(--text-col-4);
}

// Colors and text colors ----------------------------------------

:root {

    --text-col-4: rgb(62, 62, 62);

    --text-col-2: rgb(35, 35, 35);

    --primary-col-lighter: rgba(247, 247, 247, 0.323);
    --primary-col: rgba(233, 233, 233, 0.289);
    --primary-col-trans: rgba(233, 233, 233, 0.871);
    --primary-col-darker: rgba(214, 214, 214, 0.529);
    --primary-col-darkest: rgba(147, 147, 147, 0.346);
    --primary-col-darkest-e: rgba(123, 123, 123, 0.346);

    --accent-col: rgb(147, 221, 147);

    --extra-menu-color: rgb(235, 231, 255);

}

// Extra classes -------------------------------------------------

.hidden {

    transition: 0s !important;
    visibility: hidden !important;
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
    height: 0px !important;
    width: 0px !important;
    min-height: 0px !important;

    * {
        transition: 0s !important;
        visibility: hidden !important;
        margin: 0px !important;
        padding: 0px !important;
        border: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}


.burger-menu-open {

    background-color: var(--extra-menu-color) !important;
    // --burger-offset: 17px;
    --burger-offset: 100%;

    >div {

        z-index: 3;
        transition: 0.1s;
    }

    >div:nth-child(1) {

        // transform: translateY(var(--burger-offset)) rotate(45deg);
        transform: rotate(45deg);
        position: relative;
        top: var(--burger-offset);
        left: 0px;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    >div:nth-child(2) {

        // width: 100% !important;

        // transform: translateX(-30px) translateY(calc(50% - 1.5px)) scaleY(1659%);
        // transform: translateY(calc(50% - 1.5px)) scaleY(1659%);
        // transform: translateY(calc(50% - 1.5px));
        // left: calc(-50% - 10px);

        height: 150%;
        width: 200px !important;

        position: absolute;
        top: -25%;
        right: 0px;
        margin: 0px;

        background-color: var(--extra-menu-color);

        z-index: 1;
        grid-row-start: 1;
        grid-row-end: -1;
    }

    >div:nth-child(3) {

        // transform: translateY(calc(var(--burger-offset) * -1)) rotate(-45deg);
        transform: rotate(-45deg);
        position: relative;
        top: calc(-1 * var(--burger-offset));
        left: 0px;
        grid-row-start: 3;
        grid-row-end: -1;
    }
}


.burger-menu {

    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(1, 1fr);
    // margin: 10px
    width: calc(100% - 10px) !important;

    margin-top: 10px !important;
    // padding: 12px;
    padding: 15%;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.341);
    border-radius: 1000px;

    >div {

        // transition: 0.1s;
        background-color: rgb(206, 206, 206);

        width: 80%;
        margin-left: 10%;

        height: 4px;
        margin-top: calc(50%/3 - 2px);
        border-radius: 500px;
    }

    >div:nth-child(2) {

        width: 90%;
        margin-left: 5%;
    }

    &:hover {

        >div {

            width: 90%;
            margin-left: 5%;
        }

    }

    .a:hover {

        >div:nth-child(1) {

            transform: translateY(15px) rotate(45deg);
        }

        >div:nth-child(2) {

            width: 0px;
            transform: translateX(20px);
        }

        >div:nth-child(3) {

            transform: translateY(-15px) rotate(-45deg);
        }
    }
}