@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
html {
  background: linear-gradient(#c8c8f8 50%, #e6e6e6);
}

main {
  z-index: 2;
  position: relative;
  top: 0;
}

#website-description {
  padding: var(--gutter-width);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  grid-column: 1 / -1;
  margin-bottom: 20px;
  box-shadow: 0 4px 30px #5656561a;
}

#website-description h1 {
  text-align: center;
  margin: 0;
}

#website-description p {
  margin: 0;
}

#trim-editor {
  background-color: var(--primary-col);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -moz-column-gap: var(--gutter-width);
  column-gap: var(--gutter-width);
  row-gap: var(--gutter-width);
  padding: var(--gutter-width);
  border-radius: 5px;
  grid-column: 1 / -1;
  grid-template-rows: 1fr 200px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 4px 30px #5656561a;
}

@media (width <= 630px) {
  #trim-editor {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);
    height: 70vh;
  }
}

#trim-editor #trim-display {
  background-color: var(--primary-col-lighter);
  max-width: 100%;
  margin-right: 0;
}

#trim-editor #trim-display > canvas {
  max-width: 100%;
}

#trim-editor #edit-trim-model {
  grid-template-rows: auto auto;
  margin-left: 0;
  display: grid;
}

#trim-editor #edit-trim-model > * {
  grid-column-start: 0;
  grid-column-end: 1;
}

#trim-editor #edit-trim-model > #edit-all {
  margin-bottom: var(--gutter-width);
  grid-row: 1 / 2;
}

#trim-editor #edit-trim-model > #edit-single {
  grid-row: 2 / 3;
}

#trim-editor #edit-trim-model > #edit-single > .hide-leather {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}

#trim-editor #edit-trim-model > #edit-single > .hide-leather > div:nth-child(2) > img {
  opacity: .3;
}

#trim-editor #edit-trim-model > #edit-single > div {
  padding: 0 5px;
}

#trim-editor #edit-trim-model > #edit-single > div:first-of-type {
  padding-top: 5px;
}

#trim-editor #edit-trim-model > #edit-single > div:last-of-type {
  padding-bottom: 5px;
}

#trim-editor #edit-trim-model > #edit-all, #trim-editor #edit-trim-model #edit-single {
  width: 100%;
}

#trim-editor #edit-trim-model > #edit-all > div, #trim-editor #edit-trim-model #edit-single > div {
  --trim-opts-count: 5;
  grid-template-columns: repeat(var(--trim-opts-count), 1fr);
  background-color: var(--primary-col-lighter);
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  display: grid;
}

#trim-editor #edit-trim-model > #edit-all > div > div, #trim-editor #edit-trim-model #edit-single > div > div {
  margin: calc(var(--gutter-width) / 6) 0px;
  aspect-ratio: 1;
  --inner-padding: 15%;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 50ms;
  position: relative;
  top: 0;
}

#trim-editor #edit-trim-model > #edit-all > div > div > img:first-of-type, #trim-editor #edit-trim-model #edit-single > div > div > img:first-of-type {
  z-index: 5;
  image-rendering: pixelated;
  padding: var(--inner-padding);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}

#trim-editor #edit-trim-model > #edit-all > div > div > .dropdown, #trim-editor #edit-trim-model #edit-single > div > div > .dropdown {
  z-index: 200;
  background-color: var(--primary-col-trans);
  border: 1px solid var(--primary-col-darker);
  box-sizing: border-box;
  --gap: 1px;
  padding: var(--gap);
  border-radius: 5px;
  grid-template-columns: repeat(3, 1fr);
  width: 260%;
  max-width: 170px;
  height: auto;
  transition: all .1s;
  display: grid;
  position: absolute;
  top: 100%;
}

#trim-editor #edit-trim-model > #edit-all > div > div > .dropdown > img, #trim-editor #edit-trim-model #edit-single > div > div > .dropdown > img {
  aspect-ratio: 1;
  margin: var(--gap);
  width: calc(100% - var(--gap) * 2);
  image-rendering: pixelated;
}

#trim-editor #model-handling {
  grid-template-rows: 20px 1fr;
  row-gap: 20px;
  min-height: 200px;
  display: grid;
}

#trim-editor #model-handling > nav {
  -moz-column-gap: calc(var(--gutter-width) / 2);
  column-gap: calc(var(--gutter-width) / 2);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

#trim-editor #model-handling > nav > p {
  background-color: var(--primary-col-lighter);
  text-align: center;
  text-transform: capitalize;
  color: var(--text-col-2);
  border-radius: 10px;
  margin: 0;
  padding: 5px;
  transition: all .1s;
}

#trim-editor #model-handling > nav > p:hover {
  background-color: var(--primary-col-darkest-e);
}

#trim-editor #model-handling > nav .selected {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transform: scaleY(100%)translateY(8px);
}

#trim-editor #model-handling > nav .selected:hover {
  background-color: var(--primary-col-lighter);
}

#trim-editor #model-handling #handlers > div {
  background-color: var(--primary-col-lighter);
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  padding: 5px;
}

#trim-editor #model-handling #handlers > div p {
  margin: 0;
}

#trim-editor #model-handling #handlers #skin-handling > div {
  -moz-column-gap: 20px;
  grid-template-columns: 100px 1fr 1fr;
  column-gap: 20px;
  display: grid;
}

#trim-editor #model-handling #handlers #skin-handling > div > * {
  width: 100%;
}

#trim-editor #model-handling #handlers #camera-handling > div {
  -moz-column-gap: calc(var(--gutter-width) / 4);
  column-gap: calc(var(--gutter-width) / 4);
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

#trim-editor #model-handling #handlers #camera-handling > div button {
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
}

#trim-editor #model-handling #handlers #camera-handling > div .selected-rot-type {
  background-color: var(--accent-col);
}

#trim-editor #model-handling #handlers #camera-handling h2 {
  margin: 10px 0 0;
  font-size: 20px;
}

#trim-editor #model-handling #handlers #camera-handling > input {
  border: none;
  width: 100%;
}

#extra-menu {
  box-sizing: border-box;
  background-color: var(--extra-menu-color);
  z-index: 40;
  border-radius: 5px;
  grid-row: 1 / 3;
  padding: 20px;
  width: 77% !important;
}

#extra-menu * {
  transition-duration: 0s !important;
}

#extra-menu > div {
  margin: 10px 0;
}

#extra-menu h2 {
  margin: 0;
}

#extra-menu #extra-menu-armor {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

#extra-menu #leather-options-full > div {
  grid-template-columns: 1fr auto 1fr auto 1fr;
  display: grid;
}

#extra-menu #leather-options-full > div p {
  text-align: center;
  margin: 10px;
  transform: translateY(20%);
}

#extra-menu #leather-options-full > div > div {
  margin: auto;
}

#extra-menu #leather-options-full > div input {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 80%;
  height: 100%;
}

#extra-menu #give-command > p {
  color: gray;
  margin-bottom: 5px;
}

#extra-menu #give-command > input {
  color: gray;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  outline-color: #93f793;
  width: 90%;
}

#dice {
  grid-template-columns: repeat(5, 1fr);
  margin-top: 20px;
  padding: 5px;
  display: grid;
}

#dice .die {
  border: 2px solid var(--primary-col);
  box-sizing: border-box;
  aspect-ratio: 1;
  background-color: var(--primary-col-lighter);
  border-radius: 6px;
  grid-template-columns: repeat(3, 1fr);
  gap: 20%;
  width: 70%;
  margin-left: 15%;
  padding: 10%;
  display: grid;
}

#dice .die > .die-blob {
  background-color: var(--primary-col-darkest-e);
  border-radius: 100px;
}

#dice .die:active {
  transition: all .2s;
  transform: translateY(-10px);
}

.dropdown-box {
  margin: calc(var(--gutter-width) / 6) 0px;
  aspect-ratio: 1;
  --inner-padding: 15%;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 50ms;
  position: relative;
  top: 0;
}

.dropdown-box > img:first-of-type {
  z-index: 5;
  image-rendering: pixelated;
  padding: var(--inner-padding);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.dropdown-box > .dropdown {
  z-index: 100;
  background-color: var(--primary-col-trans);
  border: 1px solid var(--primary-col-darker);
  box-sizing: border-box;
  --gap: 1px;
  padding: var(--gap);
  border-radius: 5px;
  grid-template-columns: repeat(3, 1fr);
  width: 260%;
  max-width: 170px;
  height: auto;
  transition: all .1s;
  display: grid;
  position: absolute;
  top: 100%;
}

.dropdown-box > .dropdown > img {
  aspect-ratio: 1;
  margin: var(--gap);
  width: calc(100% - var(--gap) * 2);
  image-rendering: pixelated;
}

:root {
  --column-width: 100px;
  --gutter-width: 20px;
  --column-count: 8;
  --grid-width: calc(var(--column-width) * var(--column-count)  + var(--gutter-width) * (var(--column-count)  - 1));
  --grid-margin: calc((100% - var(--grid-width)) / 2);
  --column-gutter-width: calc(var(--column-width)  + var(--gutter-width));
}

* {
  font-family: Roboto, sans-serif;
}

@media (width <= 1440px) {
  :root {
    --column-width: 100px;
    --gutter-width: 20px;
    --column-count: 8;
  }
}

@media (width <= 1100px) {
  :root {
    --column-width: 70px;
    --gutter-width: 20px;
    --column-count: 8;
  }
}

@media (width <= 810px) {
  :root {
    --column-width: 50px;
    --gutter-width: 20px;
    --column-count: 8;
  }
}

@media (width <= 630px) {
  :root {
    --column-width: calc((100% - 20px - 10px * 4) / 5);
    --gutter-width: 10px;
    --column-count: 5;
  }
}

.standard-grid {
  grid-template-columns: repeat(var(--column-count), var(--column-width));
  -moz-column-gap: var(--gutter-width);
  column-gap: var(--gutter-width);
  margin-left: var(--grid-margin);
  display: grid;
}

@media (width <= 630px) {
  .standard-grid {
    margin-top: 70px;
  }
}

h1 {
  color: #161616;
}

h2 {
  color: var(--text-col-2);
}

h3 {
  color: #3d3d3d;
}

p, button {
  color: var(--text-col-4);
}

:root {
  --text-col-4: #3e3e3e;
  --text-col-2: #232323;
  --primary-col-lighter: #f7f7f752;
  --primary-col: #e9e9e94a;
  --primary-col-trans: #e9e9e9de;
  --primary-col-darker: #d6d6d687;
  --primary-col-darkest: #93939358;
  --primary-col-darkest-e: #7b7b7b58;
  --accent-col: #93dd93;
  --extra-menu-color: #ebe7ff;
}

.hidden {
  visibility: hidden !important;
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: all !important;
}

.hidden * {
  visibility: hidden !important;
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: all !important;
}

.burger-menu-open {
  --burger-offset: 100%;
  background-color: var(--extra-menu-color) !important;
}

.burger-menu-open > div {
  z-index: 3;
  transition: all .1s;
}

.burger-menu-open > div:first-child {
  top: var(--burger-offset);
  grid-row: 1 / 2;
  position: relative;
  left: 0;
  transform: rotate(45deg);
}

.burger-menu-open > div:nth-child(2) {
  background-color: var(--extra-menu-color);
  z-index: 1;
  grid-row: 1 / -1;
  height: 150%;
  margin: 0;
  position: absolute;
  top: -25%;
  right: 0;
  width: 200px !important;
}

.burger-menu-open > div:nth-child(3) {
  top: calc(-1 * var(--burger-offset));
  grid-row: 3 / -1;
  position: relative;
  left: 0;
  transform: rotate(-45deg);
}

.burger-menu {
  box-sizing: border-box;
  background-color: #ffffff57;
  border-radius: 1000px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(1, 1fr);
  padding: 15%;
  display: grid;
  width: calc(100% - 10px) !important;
  margin-top: 10px !important;
}

.burger-menu > div {
  background-color: #cecece;
  border-radius: 500px;
  width: 80%;
  height: 4px;
  margin-top: calc(16.6667% - 2px);
  margin-left: 10%;
}

.burger-menu > div:nth-child(2), .burger-menu:hover > div {
  width: 90%;
  margin-left: 5%;
}

.burger-menu .a:hover > div:first-child {
  transform: translateY(15px)rotate(45deg);
}

.burger-menu .a:hover > div:nth-child(2) {
  width: 0;
  transform: translateX(20px);
}

.burger-menu .a:hover > div:nth-child(3) {
  transform: translateY(-15px)rotate(-45deg);
}

@media (width >= 630px) {
  #trim-sets {
    --sets-width: 100px;
    top: 170px;
    left: calc(var(--grid-margin)  - var(--sets-width)  + 15px);
    position: absolute;
  }

  #trim-sets #sets-holder > div {
    box-sizing: border-box;
    width: var(--sets-width);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 80px;
    padding: 10px;
    transition: all 50ms;
  }

  #trim-sets #sets-holder > div:not(:last-child) {
    background-color: var(--primary-col-lighter);
    margin-bottom: 10px;
  }

  #trim-sets #sets-holder > div:hover {
    transform: translateX(-10px);
  }

  #trim-sets .selected-trim {
    color: #fff;
    transform: translateX(-10px);
    background-color: var(--accent-col) !important;
  }

  #trim-sets #add-trim-set-btn {
    background-color: var(--primary-col-darkest);
  }

  #trim-sets #add-trim-set-btn > div {
    aspect-ratio: 1;
    box-sizing: border-box;
    background-color: #ffffff9e;
    border-radius: 1000px;
    height: 100%;
    padding: 10px;
    display: grid;
  }

  #trim-sets #add-trim-set-btn > div > div {
    --line-thickness: 6px;
    background-color: var(--accent-col);
    border-radius: 100px;
    grid-row-start: 1;
    grid-column-start: 1;
  }

  #trim-sets #add-trim-set-btn > div > div:first-of-type {
    width: var(--line-thickness);
    margin-left: calc(50% - var(--line-thickness) / 2);
    height: 100%;
  }

  #trim-sets #add-trim-set-btn > div > div:last-of-type {
    height: var(--line-thickness);
    margin-top: calc(50% - var(--line-thickness) / 2);
    width: 100%;
  }

  #trim-sets #trim-delete {
    background-color: #eaeaea;
    border-radius: 100px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 120px;
  }

  #trim-sets #trim-delete:hover {
    background-color: #93f793 !important;
  }

  #trim-sets #trim-delete img {
    opacity: .4;
    width: 80%;
    height: 80%;
    padding: 10%;
  }

  #toggle-trim-sets {
    visibility: hidden;
  }
}

@media (width <= 630px) {
  #trim-sets {
    --sets-width: 80px;
    z-index: 250;
    transition: all .2s;
    position: absolute;
    top: 170px;
    left: -10px;
  }

  #trim-sets #sets-holder > div {
    box-sizing: border-box;
    width: var(--sets-width);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 80px;
    padding: 10px;
    transition: all 50ms;
  }

  #trim-sets #sets-holder > div:not(:last-child) {
    background-color: var(--primary-col-lighter);
    margin-bottom: 10px;
  }

  #trim-sets #sets-holder > div:hover {
    transform: translateX(10px);
  }

  #trim-sets .selected-trim {
    color: #fff;
    transform: translateX(10px);
    background-color: var(--accent-col) !important;
  }

  #trim-sets #add-trim-set-btn {
    background-color: var(--primary-col-darkest);
  }

  #trim-sets #add-trim-set-btn > div {
    aspect-ratio: 1;
    box-sizing: border-box;
    background-color: #ffffff9e;
    border-radius: 1000px;
    height: 100%;
    padding: 10px;
    display: grid;
  }

  #trim-sets #add-trim-set-btn > div > div {
    --line-thickness: 6px;
    background-color: var(--accent-col);
    border-radius: 100px;
    grid-row-start: 1;
    grid-column-start: 1;
  }

  #trim-sets #add-trim-set-btn > div > div:first-of-type {
    width: var(--line-thickness);
    margin-left: calc(50% - var(--line-thickness) / 2);
    height: 100%;
  }

  #trim-sets #add-trim-set-btn > div > div:last-of-type {
    height: var(--line-thickness);
    margin-top: calc(50% - var(--line-thickness) / 2);
    width: 100%;
  }

  #trim-sets #trim-delete {
    background-color: #eaeaea;
    border-radius: 100px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 120px;
  }

  #trim-sets #trim-delete:hover {
    background-color: #93f793 !important;
  }

  #trim-sets #trim-delete img {
    opacity: .4;
    width: 80%;
    height: 80%;
    padding: 10%;
  }

  #toggle-trim-sets {
    aspect-ratio: 1;
    z-index: 1250;
    border: none;
    border-radius: 100px;
    position: fixed;
    top: 10px;
    left: 10px;
  }

  .hide-trim-sets {
    left: -190px !important;
  }
}

#popups {
  position: relative;
  top: 0;
  left: 0;
}

#popups > #dark-back {
  z-index: 3;
  background-color: #0000005a;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#popups > #popups-holder {
  width: 100px;
}

#popups > #popups-holder > div {
  --div-width: 350px;
  --div-height: 200px;
  width: var(--div-width);
  height: var(--div-height);
  top: calc(45vh - var(--div-height) / 2);
  left: calc(50% - var(--div-width) / 2);
  z-index: 5;
  background-color: #eee;
  border-radius: 5px;
  position: absolute;
}

#graphics {
  position: fixed;
  top: 0;
  left: 0;
}

#graphics #sky {
  width: 100vw;
  height: 100vh;
}

#all-trims > p {
  grid-column: 1 / -1;
  margin-top: 0;
  margin-bottom: 5px;
}

#selected-trims, #all-trims {
  -moz-column-gap: 5px;
  grid-column: 1 / -1;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-bottom: 20px;
  display: grid;
  z-index: -1 !important;
}

@media (width <= 630px) {
  #selected-trims, #all-trims {
    grid-template-columns: repeat(1, 1fr);
  }
}

#selected-trims > h2, #all-trims > h2 {
  grid-column: 1 / -1;
  margin: 5px 0;
}

#selected-trims > .trim-card, #all-trims > .trim-card {
  background-color: var(--primary-col);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  --craft-b-w: 30px;
  border-radius: 5px;
  grid-template-columns: 1fr auto;
  padding: 10px;
  display: grid;
  box-shadow: 0 4px 30px #5656561a;
}

@media (width <= 814px) {
  #selected-trims > .trim-card, #all-trims > .trim-card {
    padding-bottom: 65px;
  }
}

#selected-trims > .trim-card h2, #selected-trims > .trim-card h3, #all-trims > .trim-card h2, #all-trims > .trim-card h3 {
  color: #292929;
  text-transform: capitalize;
  margin-top: 0;
}

#selected-trims > .trim-card h2, #all-trims > .trim-card h2 {
  vertical-align: middle;
  display: inline-block;
}

#selected-trims > .trim-card > div > .trim-img, #all-trims > .trim-card > div > .trim-img {
  image-rendering: pixelated;
  height: 20%;
  margin-right: 5px;
}

#selected-trims > .trim-card > div > img, #all-trims > .trim-card > div > img {
  transform: translateY(3px);
}

#selected-trims > .trim-card h3, #all-trims > .trim-card h3 {
  text-align: center;
  margin-right: 2px;
}

#selected-trims > .trim-card p, #all-trims > .trim-card p {
  color: #737373;
  margin-top: 0;
  margin-bottom: 5px;
  display: inline-block;
}

#selected-trims > .trim-card input, #all-trims > .trim-card input {
  box-sizing: border-box;
  color: #5f5f5f;
  background-color: #ffffffb1;
  border: none;
  border-radius: 5px;
  width: 50px;
  padding-left: 5px;
}

#selected-trims > .trim-card .desc, #all-trims > .trim-card .desc {
  margin-bottom: 20px;
  color: #404040 !important;
}

#selected-trims > .trim-card .craft, #all-trims > .trim-card .craft {
  background-color: var(--primary-col);
  -moz-column-gap: 4px;
  border-radius: 2px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  padding: 4px;
  display: grid;
  box-shadow: 0 4px 30px #5656561a;
}

#selected-trims > .trim-card .craft > div, #all-trims > .trim-card .craft > div {
  width: var(--craft-b-w);
  height: var(--craft-b-w);
  background-color: #ffffff79;
  border-radius: 2px;
}

#selected-trims > .trim-card .craft > div > img, #all-trims > .trim-card .craft > div > img {
  --scale: .9;
  width: calc(100% * var(--scale));
  height: calc(100% * var(--scale));
  margin: calc((100% - var(--scale) * 100%) / 2);
  image-rendering: pixelated;
}

#more-tools {
  --marging-l: 30%;
  -moz-column-gap: 15px;
  width: calc(100% - var(--marging-l));
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  display: grid;
  margin-left: calc(var(--marging-l) / 2) !important;
}

@media (width <= 1100px) {
  #more-tools {
    --marging-l: 50px;
  }
}

#more-tools #btn-click-text {
  text-align: center;
  color: #27272700;
  grid-column: 1 / 4;
  width: 50%;
  margin-left: 25%;
  transition: all .4s;
}

#more-tools .btn-text-clicked {
  color: #272727 !important;
}

#more-tools > div {
  width: 100%;
  display: block;
}

#more-tools > div > button {
  aspect-ratio: 1;
  background-color: var(--primary-col-lighter);
  border: none;
  border-radius: 5px;
  width: 100%;
  transition: all .1s;
  display: grid;
}

#more-tools > div > button > img {
  --w: 60%;
  width: var(--w);
  margin-left: calc(50% - var(--w) / 2);
  filter: brightness(0) invert() brightness(.3);
  display: block;
}

#more-tools > div > button:hover {
  transform: scale(1.1);
}

#more-tools #discord-logo {
  padding: 8px 0;
}

#more-tools input {
  background-color: var(--primary-col);
  border: 1px solid #ededed;
}

#phone-nav {
  grid-column: 1 / -1;
  width: 100%;
}

#phone-nav > nav {
  -moz-column-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
  width: 100%;
  height: 50px;
  display: grid;
}

#phone-nav > nav > button {
  border: none;
  border-radius: 5px;
  width: 100%;
}

#phone-nav > nav .selected {
  background-color: var(--accent-col);
}

@media (width <= 630px) {
  #trim-disp-options {
    max-width: 45vh;
  }
}

.cookie-banner {
  z-index: 10030;
  background-color: #e4e4e4;
  border-radius: 4px;
  width: 500px;
  max-width: 96%;
  max-height: 96vh;
  padding: 20px;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 260px);
  box-shadow: 0 0 1px 800vw #0003;
}

@media (width <= 600px) {
  .cookie-banner {
    box-sizing: border-box;
    bottom: 10px;
    left: 2%;
  }

  .cookie-banner .cookies-text {
    max-height: 75vh;
    overflow: auto;
    box-shadow: inset 0 -10px 10px -10px #9f9f9f;
  }
}

.cookie-banner #consent-toggle {
  padding-top: 20px;
}

.cookie-banner h3 {
  text-align: center;
  letter-spacing: 1px;
}

.cookie-banner p {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 16px;
}

.cookie-banner button {
  text-transform: capitalize;
  border: 2px solid #9d9d9d;
  border-radius: 5px;
  padding: 10px;
}

.cookie-banner button:hover {
  border: 2px solid #3d3d3d !important;
}

.cookie-banner .consent-buttons {
  -moz-column-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
  margin-top: 3px;
  display: grid;
}

.cookie-banner .consent-buttons .consent-all-cookies {
  border: 2px solid var(--accent-col);
}

.cookie-banner .consent-buttons .customise-cookies {
  background-color: #ffffff3b;
  border: 1px solid #868686;
}

.switch {
  width: 60px;
  height: 34px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch .slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.switch input:checked + .slider {
  background-color: var(--accent-col);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  transform: translateX(26px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.toggle-label {
  vertical-align: middle;
  width: 120px;
  display: inline-block;
  transform: translateY(30%);
}

#privacy-btn-wrapper {
  position: absolute;
  right: 20px;
}

.clear-font * {
  font-family: Arial, Helvetica, sans-serif !important;
}

#update-cookies-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

footer {
  z-index: 100;
  position: relative;
  top: 0;
}

footer div {
  box-shadow: 40vw 0px 0px 0px var(--primary-col), -40vw 0px 0px 0px var(--primary-col);
  background-color: var(--primary-col);
  padding: var(--gutter-width);
  border-radius: 10px;
  grid-column: 1 / -1;
}

footer div ul {
  -moz-column-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  padding-left: 0;
  display: grid;
}

footer div ul li {
  text-align: center;
  display: block;
}

footer div ul li a {
  font-size: 20px;
}

footer div ul li a:not(.selected) {
  text-decoration: none;
}

/*# sourceMappingURL=index.62b94b7e.css.map */
