#phone-nav {


    grid-column-start: 1;
    grid-column-end: -1;
    width: 100%;

    >nav {
        width: 100%;
        height: 50px;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;

        >button {

            border-radius: 5px;
            border: none;
            width: 100%;
        }

        .selected {
            background-color: var(--accent-col);
        }
    }
}

#trim-disp-options {


    // shrink the height if its bigger then max width


    // @media (max-width: 460px) {

    //     width: 100% !important;
    //     margin-left: 0% !important;
    // }

    @media (max-width: 630px) {
        
        max-width: 45vh;
    }
}