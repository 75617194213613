#popups {

    position: relative;
    top: 0px;
    left: 0px;

    >#dark-back {

        background-color: rgba(0, 0, 0, 0.353);
        width: 100%;
        height: 100%;

        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;
    }

    >#popups-holder {

        width: 100px;

        >div {

            --div-width: 350px;
            --div-height: 200px;

            background-color: rgb(238, 238, 238);
            border-radius: 5px;

            width: var(--div-width);
            height: var(--div-height);
            
            position: absolute;
            top: calc(45vh - var(--div-height)/2);
            left: calc(50% - var(--div-width)/2);
            z-index: 5;
        }
    }
}