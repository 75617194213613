footer {

    z-index: 100;
    position: relative;
    top: 0px;
    
    div {

        ul {

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 10px;
            padding-left: 0px;
            
            li{
                
                display: block;
                text-align: center;

                a{

                    font-size: 20px;
                    
                    &:not(.selected){
                        // color: var(--accent-col);
                        text-decoration: none;
                    }
                }
            }
        }

        box-shadow: 40vw 0px 0px 0px var(--primary-col), -40vw 0px 0px 0px var(--primary-col);

        background-color: var(--primary-col);
        padding: var(--gutter-width);
        border-radius: 10px;

        grid-column-start: 1;
        grid-column-end: -1;
    }
}